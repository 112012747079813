body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  display: block;
  padding-left: 50vh;
}

.sidebar {
  position: fixed;
  width: 50vh;
  height: 100vh;
  background-color: #f0f0f0;
  transition: all 0.3s ease;
  left: 0;
  top: 0;
}

.sidebar ul {
  list-style: none;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}

.bi {
  color: #696969;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  fill: currentColor;
}

.button-link {
  display: inline-block;
  width: 180px;
  padding: 0 10px 0 10px;
  margin: 20px 0 10px 0;
  text-decoration: none;
  border: 1px solid;
  border-radius: 5px;
  font-family: Arial;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; /* Add transition effects */
}

.sidebar a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.sidebar a:hover {
  color: #007bff;
}

.main-content {
  width: 80%;
  background-color: #ffffff;
  padding: 20px 0 0 40px;
  border: aqua 2px;
}

table {
  border-collapse: separate;
  border-spacing: 10px;
}

td, th {
  padding: 10px;
  border: 1px solid #ccc;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.barlow-thin {
  font-family: "Barlow", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.barlow-extralight {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.barlow-light {
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.barlow-regular {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-medium {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.barlow-semibold {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.barlow-bold {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-extrabold {
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.barlow-black {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.barlow-thin-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.barlow-extralight-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.barlow-light-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.barlow-regular-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.barlow-medium-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.barlow-semibold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.barlow-bold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.barlow-extrabold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.barlow-black-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-style: italic;
}
