.home-container {
    width: 100vh;
    margin: 0 auto;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    cursor: pointer;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.page-icons {
    justify-content: space-between;
}

.mfa-container{
    display: flex;
    justify-content: space-between;
    padding: 50px 0 30px 0;
    width: 100vh;
    margin-top: 30px;
}

.reset-pwd-btn {
    padding-top: 30px;
}

p {
    color: #006B5B;
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    background: #fff;
    border-bottom: 1px solid #dedede;
    z-index: 999
}

.header p {
    width: 100vh;
    margin: 0 auto;
    line-height: 100px;
    font-size: 22px;
    color: #006B5B;
    padding: 0;
}

.resetPassword {
    margin: 110px 0 20px 0;
}

.action-font {
    padding-right: 60px;
}

.logout-section {
    margin-top: 60px;
    margin-bottom: 36px;
}

.logoutBtn {
    margin-top: 20px;
}

.contaier_showbox, .contaier_showbox_back {
    position: fixed;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.contaier_showbox_back {
    position:absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
}
.container_showbox_title {
    background: #fff;
    z-index: 100;
    width: 500px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -100px 0 0 -250px;
    border-radius: 20px;
}
.container_showbox_title h3 {
    padding: 20px;
    text-align: left;
    display: block;
    color: #000;
    font-size: 24px;
}
.container_showbox_title p {
    color: #333;
    text-align: left;
    font-size: 16px;
    padding-left: 20px;
}
.container_showbox_footers {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.container_showbox_footers span {
    display: inline-block;
    font-size: 16px;
    background: #006B5B;
    color: #fff;
    border-radius: 30px;
    padding: 0 30px;
    margin: 0 0 0 20px;
    line-height: 36px;
    cursor: pointer;
    border: 1px solid #dedede
}
.container_showbox_footers .container_showbox_footers_cancel {
    background: #e8e8e8;
    color: #333;
}

.footer {
    position:fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #dedede;
    background: #fff;
    font-size: 14px;
    color: #666;
}
.footer a {
    color: inherit;
    text-decoration: none;
}

.copyright {
    width: 70vh;
    /*text-align: center;
    margin: 0 auto;*/
    margin-left: 30vh;
    display: inline-block;
}
.privacy {
    float: right;
    margin-right: 30vh;
}
.terms {
    float: right;
    margin-right: 10vh;
}

.disabledMsg span {
    float: right;
}